.ui.segment.with-header {
    padding: 0.5em 0 0.5em 0;
    display: block;
}

.ui.segment.with-header > .header {
    padding: 0 0.5em 0 0.75em;
    align-items: center !important;
    flex-wrap: nowrap;
}

.ui.segment.with-header .title {
    font-size: 1.2rem;
}

.ui.segment.with-header > .content {
    background-color: var(--primary-background-color);
    margin: 0.5rem 1em 0.25em 1em;
}

.ui.segment.with-header > .header a {
    color: var(--primary-color);
}

.ui.segment.with-header .actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.ui.segment.with-header .actions svg {
    font-size: 1.2rem;
    vertical-align: middle;
}

@media only screen and (max-width: 767px) {
    .ui.segment.with-header > .header {
        padding: 0em 0.5em 0em 0.75em;
    }
    .ui.segment.with-header > .content {
        background-color: var(--primary-background-color);
        margin: 0.5em;
    }
    .ui.segment.with-header .title {
        font-size: 1.1rem;
    }
}
