.emoji-icon {
    height: 1.5rem;
    width: 1.5rem;
    background-color: var(--secondary-color);
    color: var(--primary-color-inverted);
    border-radius: 15%;
    padding: 0.1em;
    font-size: 1rem;
    text-align: center;
}

.emoji-icon-notification {
    /* A little red bubble positioned to the bottom right of the parent element */
    position: absolute;
    bottom: -0.5rem;
    right: -0.5rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    border-radius: 40%;
    /* A softer red */
    background-color: #ff4d4f;
    padding: 0rem 0.15rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.675rem;
}

.emoji-icon.yellow {
    background-color: var(--airport-yellow);
    color: var(--primary-color);
}

.emoji-icon.black {
    background-color: var(--primary-color);
    color: var(--airport-yellow);
}

.emoji-icon.blackwhite {
    background-color: var(--primary-color);
    color: var(--primary-background-color);
}
